import { useState, useEffect } from 'react'

function TTT() {

    const [ count, setCout ] = useState('550k');

    return <span>
        { count }
    </span>;
}

export default TTT;
