import Header from './sections/landing/Header';
import Features from './sections/landing/Features';
import Projects from './sections/landing/Projects';
import Team from './sections/landing/Team';
import Contact from './sections/landing/Contact';
import Divider from '../components/Divider';

function Landing() {
    return <div className='landing'>
        <Header />
        <main>
            <Features />
            <Divider />
            <Projects />
            <Team />
            <Contact />
        </main>
    </div>;
}

export default Landing;
