import { useTranslation } from 'react-i18next';
import TTTCount from '../../../components/unsercounts/TTT'
import OWSCount from '../../../components/unsercounts/OWS'
import AnimatedBlock from '../../../components/AnimatedBlock';
import Image from 'react-image-webp';

function Projects() {

    const { t } = useTranslation();

    return <section id="projects">
         <AnimatedBlock>
            <article className='project right'>
                <Image
                    alt="BlueFoxHost Landing Page by Plutorix"
                    className="preview"
                    src={ require('../../../assets/projects/img/blueFox.png') }
                    webp={ require('../../../assets/projects/blueFox.webp') }
                />
                <div className="info">
                    <h3 className='title'>{t("BlueFox Landing Page")}</h3>
                    <p className='description'>
                        {t("BlueFox description")}
                    </p>
                </div>
            </article>
        </AnimatedBlock>
        <AnimatedBlock>
            <article className='project left'>
                <Image
                    alt="OneWordStory Landing Page by Plutorix"
                    className="preview"
                    src={ require('../../../assets/projects/img/ows.png') }
                    webp={ require('../../../assets/projects/ows.webp') }
                />
                <div className="info">
                    <h3 className='title'>{t("OneWordStory Discord Bot")}</h3>
                    <p className='description'>
                        {t("OWS description 1")} <OWSCount />{t("OWS description 2")}
                    </p>
                </div>
            </article>
        </AnimatedBlock>
        <AnimatedBlock>
            <article className='project right'>
                <Image
                    alt="Enorati Web App by Plutorix"
                    className="preview"
                    src={ require('../../../assets/projects/img/enorati.png') }
                    webp={ require('../../../assets/projects/enorati.webp') }
                />
                <div className="info">
                    <h3 className='title'>Enorati<br />Tool For Teachers</h3>
                    <p className='description'>
                        {t("Enorati description")}
                    </p>
                </div>
            </article>
        </AnimatedBlock>
        <AnimatedBlock>
            <article className='project left'>
                <Image
                    alt="TicTacToe Landing Page by Plutorix"
                    className="preview"
                    src={ require('../../../assets/projects/img/ttt.png') }
                    webp={ require('../../../assets/projects/ttt.webp') }
                />
                <div className="info">
                    <h3 className='title'>{t("TicTacToe Discord Bot")}</h3>
                    <p className='description'>
                        {t("TicTacToe Discord Bot description 1")} <TTTCount />{t("TicTacToe Discord Bot description 2")}
                    </p>
                </div>
            </article>
        </AnimatedBlock>
        <AnimatedBlock>
            <article className='project right'>
                <Image
                    alt="GiveCord Dashboard by Plutorix"
                    className="preview"
                    src={ require('../../../assets/projects/img/givecord.png') }
                    webp={ require('../../../assets/projects/givecord.webp') }
                />
                <div className="info">
                    <h3 className='title'>{t("GiveCord Dashboard")}</h3>
                    <p className='description'>
                        {t("GiveCord Dashboard description")}
                    </p>
                </div>
            </article>
        </AnimatedBlock>
    </section>;
}

export default Projects;
