import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';
import Landing from './views/Landing.js';
import './translate';

function App() {

  const { i18n } = useTranslation();
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    navigator.language == 'de' && i18n.changeLanguage('de');
    new Promise((resolve) => setTimeout(() => resolve(), 1000))
    .then(() => document.getElementById('loader').classList.remove('loading'))
    .then(() => setLoading(false));
  }, []);

  if (loading) return null

  return (
    <div id='app' className="app container">
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
