import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/logo.svg';
import BurgerMenu from '../assets/burgermenu.svg'

function Navbar() {

    const { t } = useTranslation();

    return <nav className='navbar'>
        <div className='navbar-container'>
            <Link to="/" className='logo'>
                <img src={ Logo } alt='logo' />
                <h1>Plutorix</h1>
            </Link>
            <div className="left">
                <div className="links">
                    <a href='#projects'>{t("Projects")}</a>
                    <a href='#team'>{t("Team")}</a>
                    <a href='#contact'>{t("Contact")}</a>
                </div>
                <img onClick={() => document.getElementById('mobileNav').classList.add('visible')} src={BurgerMenu} alt="BurgerMenu" className="burgermenu" />
            </div>
        </div>
        <div id="mobileNav" onClick={() => document.getElementById('mobileNav').classList.remove('visible')}>
            <Link className='button' to="/">{t("Home")}</Link>
            <a className='button' href="/#projects">{t("Projects")}</a>
            <a className='button' href="/#team">{t("Team")}</a>
            <a className='button' href="/#contact">{t("Contact")}</a>
        </div>
    </nav>;
}

export default Navbar;
