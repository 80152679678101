import { useTranslation } from 'react-i18next';
import FeatureCheckGraphic from '../../../assets/feature_checks.svg'
import FeatureReviewsGraphic from '../../../assets/feature_reviews.svg'
import { motion, AnimatePresence } from 'framer-motion';

function Features() {

    const { t } = useTranslation();
    const animationDuration = 0.55;

    return <section id='features'>
        <AnimatePresence exitBeforeEnter>
            <motion.div className="row left"
                animate={ { opacity: 1, y: 0 } }
                initial={ { opacity: 0, y: 20 } }
                exit={ { opacity: 0, y: -20 } }
                transition={ { duration: animationDuration } }
            >
                <img src={ FeatureCheckGraphic } alt="In Time Delivery" className="graphic" />
                <div className="content">
                    <h3 className='title'>{t("Always on time!")}</h3>
                    <p className='description'>
                       {t("Always on time! description")} 
                    </p>
                </div>
            </motion.div>
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
            <motion.div className="row right"
                animate={ { opacity: 1, y: 0 } }
                initial={ { opacity: 0, y: 20 } }
                exit={ { opacity: 0, y: -20 } }
                transition={ { duration: animationDuration } }
            >
                <img src={ FeatureReviewsGraphic } alt="In Time Delivery" className="graphic" />
                <div className="content">
                    <h3 className='title'>{t("Great Quality!")}</h3>
                    <p className='description'>
                        {t("Great Quality! description")} 
                    </p>
                </div>
            </motion.div>
        </AnimatePresence>
    </section>;
}

export default Features;
