import { useTranslation } from 'react-i18next';

function Contact() {

    const { t } = useTranslation();

    return <section id="contact">
        <h3 className='title'>{t("Contact")}</h3>
        <div className="row">
            <a href="mailto:info@plutorix.com?subject=Mail to Plutorix" className="button">Email</a>
            <a href="https://www.linkedin.com/in/plutorix-inc-b2b4a7230/" className="button">Linkedin</a>
            <a href="https://twitter.com/plutorixinc" className="button">Twitter</a>
            <a href="https://github.com/plutorixinc" className="button">Github</a>
        </div>
    </section>;
}

export default Contact;
