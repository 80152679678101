import { motion } from 'framer-motion';

function AnimatedBlock({ children }) {
    return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0.8 }
          }}
        >
          {children}
        </motion.div>
    );
}

export default AnimatedBlock;
