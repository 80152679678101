import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            "Home": "Home",
            "Projects": "Projects",
            "Team": "Team",
            "Contact": "Contact",
            "Contact us": "Contact us",
            "Always on time!": "Always on time!",
            "Always on time! description": "All of our projects  are finished before the deadline with enough time to check our results thoroughly. How do we achieve such a great feat? Do we just agree on a ridiculously long deadline? No! Even though we may be a small team, we work with 100% efficiency and teamwork, which reduces the time for completion dramatically!",
            "Great Quality!": "Great Quality!",
            "Great Quality! description": "After years of experience and lots of feedback we have reached a point, where we can consistently deliver Projects with some of the highest quality available. But that isn’t just our opinion! All of our previous clients have been more than satisfied with the results, which reflects itself in our reviews!",
            "BlueFox Landing Page": "BlueFoxHost Landing Page",
            "BlueFox description": "Like many other companies today, BlueFoxHost needed a heavily revamped web presence to withstand growing demand and to reach new customers. In cooperation with Plutorix, the hosting service was able to launch a highly professional website in a very short time. The new site helps customers to get a quick overview of BlueFox's services and also allows users to dynamically explore server locations and their connectivity.",
            "OneWordStory Discord Bot": "OneWordStory Discord Bot",
            "OWS description 1": "A Multiplayer Discord game, where you have to cooperate with your teammates to create a wonderful story. Sounds boring? WRONG! There will always be that one crazy guy, who makes everything go wild! And our users agree! With a userbase of",
            "OWS description 2": ", the bot has already reached first place in its category by a landside.",
            "Enorati Tool For Teachers": "Enorati Tool For Teachers",
            "Enorati description": "There was a constant struggle for teachers to prepare their lessons at home and bringing them to class… Until now! With Enorati you can automatically upload all of your teaching files and download them during your lessons! This innovative tool has already been used practically and thus recieved countless positive reviews by real teachers!",
            "TicTacToe Discord Bot": "TicTacToe Discord Bot",
            "TicTacToe Discord Bot description 1": "This bot lets you compete in the all-time classic game „Tic Tac Toe“ against players worldwide! But it doesn’t stop there! You can also host tournaments and flex on your friends through crazy colour-combos! We believe the game to be great fun and our users seem to agree! With a userbase of",
            "TicTacToe Discord Bot description 2": ", the bot has already reached first place in its category by far.",
            "GiveCord Dashboard": "GiveCord Dashboard",
            "GiveCord Dashboard description": "The Dashboard enables its users to host and organise any giveaway with ease while being very easy to use! This project has a special place in our hearts - not because of the quality, but because it was our very first Freelance job and thus our very first review: „great quality, great communication, fast and relatively cheap! Thanks a lot guys!“",
            "Meet our team": "Meet our team",
            "Graphic Design": "Graphic Design",
            "Development": "Development",
            "Communications": "Communications",
            "Finance": "Finance",
            "All rights reserved": "All rights reserved",
        }
    },
    de: {
        translation: {
            "Home": "Start",
            "Projects": "Projekte",
            "Team": "Team",
            "Contact": "Kontakt",
            "Contact us": "Kontakt",
            "Always on time!": "Immer rechtzeitig!",
            "Always on time! description": "Alle unsere Projekte werden vor dem Abgabetermin fertiggestellt, so dass wir genügend Zeit haben, unsere Ergebnisse gründlich zu überprüfen. Wie erreichen wir eine solche Leistung? Einigen wir uns einfach auf eine lächerlich lange Frist? Nein! Auch wenn wir ein kleines Team sind, arbeiten wir mit 100%iger Effizienz und Teamwork, was die Zeit bis zur Fertigstellung drastisch verkürzt!",
            "Great Quality!": "Großartige Qualität!",
            "Great Quality! description": "After years of experience and lots of feedback we have reached a point, where we can consistently deliver Projects with some of the highest quality available. But that isn’t just our opinion! All of our previous clients have been more than satisfied with the results, which reflects itself in our reviews!",
            "Great Quality! description": "Nach jahrelanger Erfahrung und vielen Rückmeldungen haben wir einen Punkt erreicht, an dem wir durchgängig Projekte mit einer der höchsten verfügbaren Qualitäten liefern können. Aber das ist nicht nur unsere Meinung! Alle unsere bisherigen Kunden waren mehr als zufrieden mit den Ergebnissen, was sich auch in unseren Bewertungen widerspiegelt!",
            "BlueFox Landing Page": "BlueFoxHost Landing Page",
            "BlueFox description": "Wie viele andere Unternehmen benötigte auch BlueFoxHost einen stark überarbeiteten Webauftritt, um der wachsenden Nachfrage gerecht zu werden und neue Kunden zu erreichen. In Zusammenarbeit mit Plutorix konnte der Hosting-Dienstleister in kürzester Zeit eine hochprofessionelle Website auf die Beine stellen. Die neue Seite hilft den Kunden, sich einen schnellen Überblick über die Dienstleistungen von BlueFox zu verschaffen und ermöglicht es den Nutzern, Serverstandorte und deren Konnektivität dynamisch zu erkunden.",
            "OneWordStory Discord Bot": "OneWordStory Discord Bot",
            "OWS description 1": "Ein Multiplayer-Discord-Spiel, bei dem du mit deinen Mitspielern zusammenarbeiten musst, um eine wunderbare Geschichte zu erschaffen. Klingt langweilig? FALSCH! Es wird immer diesen einen Verrückten geben, der alles durcheinander bringt! Und unsere Nutzer sehen das genauso! Mit einer Nutzerbasis von",
            "OWS description 2": " hat der Bot bereits den ersten Platz in seiner Kategorie mit großem Abstand erreicht.",
            "Enorati Tool For Teachers": " Enorati Werkzeug für Lehrer",
            "Enorati description": "Es war ein ständiger Kampf für LehrerInnen, ihre Lektionen zu Hause vorzubereiten und sie in die Klasse zu bringen... Bis jetzt! Mit Enorati können Sie alle Ihre Unterrichtsdateien automatisch hochladen und während des Unterrichts herunterladen! Dieses innovative Tool wurde bereits in der Praxis eingesetzt und hat daher zahlreiche positive Bewertungen von echten Lehrern erhalten!",
            "TicTacToe Discord Bot": "TicTacToe Discord Bot",
            "TicTacToe Discord Bot description 1": "Mit diesem Bot kannst du im klassischen Spiel Tic Tac Toe gegen Spieler auf der ganzen Welt antreten! Aber das ist noch nicht alles! Du kannst auch Turniere veranstalten und deine Freunde durch verrückte Farbkombinationen herausfordern! Wir glauben, dass das Spiel großen Spaß macht, und unsere Nutzer scheinen uns zuzustimmen! Mit einer Nutzerbasis von",
            "TicTacToe Discord Bot description 2": " hat der Bot bereits mit Abstand den ersten Platz in seiner Kategorie erreicht.",
            "GiveCord Dashboard": "GiveCord Dashboard",
            "GiveCord Dashboard description": 'Das Dashboard ermöglicht es seinen Nutzern, jedes Werbegeschenk mit Leichtigkeit zu hosten und zu organisieren und ist dabei sehr einfach zu bedienen! Dieses Projekt hat einen besonderen Platz in unserem Herzen - nicht wegen der Qualität, sondern weil es unser allererster Freelance-Auftrag und damit unsere allererste Bewertung war: "Tolle Qualität, tolle Kommunikation, schnell und günstig! Vielen Dank!"',
            "Meet our team": "Lerne unser Team kennen",
            "Graphic Design": "Grafikdesign",
            "Development": "Entwicklung",
            "Communications": "Kommunikation",
            "Finance": "Finanzen",
            "All rights reserved": "Alle Rechte vorbehalten",
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        interpolation: {
            escapeValue: false
        }
    })

export default i18n;