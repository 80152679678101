import { useState, useEffect } from 'react'

function TTT() {

    const [ count, setCout ] = useState('300k');

    useEffect(() => {   
        fetch('https://api.tictactoe-bot.xyz/stats')
        .then(res => res.json())
        .then(data => setCout(abbrNum(data.users, 0)));
    }, []);

    function abbrNum(number, decPlaces) {
        decPlaces = Math.pow(10,decPlaces);
        var abbrev = [ "k", "m", "b", "t" ];
        for (var i=abbrev.length-1; i>=0; i--) {
            var size = Math.pow(10,(i+1)*3);
            if(size <= number) {
                number = Math.round(number*decPlaces/size)/decPlaces;
                if((number == 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }
                number += abbrev[i];
                break;
            }
        }
    
        return number;
    }

    return <span>
        { count }
    </span>;
}

export default TTT;
