import { useTranslation } from 'react-i18next';
import HeaderGraphic from '../../../assets/header.svg'

function Header() {

    const { t } = useTranslation();

    return <header>
        <img src={ HeaderGraphic } alt="Plutorix Header Graphic" className="graphic" />
        <div className="box">
            <h1>Plutorix</h1>
            <h2>Freelance Team</h2>
            <a href="#contact" className="button">{t("Contact us")}</a>
        </div>
    </header>;
}

export default Header;
