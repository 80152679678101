import { useTranslation } from 'react-i18next';
import Image from 'react-image-webp';
import LinkedInIcon from '../../../assets/icons/linkedin.svg';
import GitHubIcon from '../../../assets/icons/github.svg';

function Team() {

    const { t } = useTranslation();

    return <section id="team">
        <h3 className='title'>{t("Meet our team")}</h3>
        <div className="members">
            <div className="member">
                <Image
                    alt="Mika Egelhaaf"
                    className="avatar"
                    src={ require('../../../assets/team/img/Mika_Egelhaaf.jpg') }
                    webp={ require('../../../assets/team/Mika_Egelhaaf.webp') }
                />
                <div className="info">
                    <p className="name">Mika Egelhaaf</p>
                    <p className="function">{t("Development")}</p>
                    <div className="links">
                        <a href="https://www.linkedin.com/in/mieg/" className="link"><img src={LinkedInIcon} alt="LinkedIn" /></a>
                        <a href="https://github.com/DJCoolDev" className="link"><img src={GitHubIcon} alt="Github" /></a>
                    </div>
                </div>
            </div>
            <div className="member">
                <Image
                    alt="Ferdinand Duschka"
                    className="avatar"
                    src={ require('../../../assets/team/img/Ferdinand_Duschka.jpg') }
                    webp={ require('../../../assets/team/Ferdinand_Duschka.webp') }
                />
                <div className="info">
                    <p className="name">Ferdinand Duschka</p>
                    <p className="function">{t("Development")}</p>
                    <div className="links">
                        <a href="https://www.linkedin.com/in/ferdinand-duschka-803619229/" className="link"><img src={LinkedInIcon} alt="LinkedIn" /></a>
                        <a href="https://github.com/fduschka" className="link"><img src={GitHubIcon} alt="Github" /></a>
                    </div>
                </div>
            </div>
            <div className="member">
                <Image
                    alt="Nikolaus Kaminski"
                    className="avatar"
                    src={ require('../../../assets/team/img/Nikolaus_Kaminski.jpg') }
                    webp={ require('../../../assets/team/Nikolaus_Kaminski.webp') }
                />
                <div className="info">
                    <p className="name">Nikolaus Kaminski</p>
                    <p className="function">{t("Communications")}</p>
                    <div className="links">
                        <a href="https://www.linkedin.com/in/nikolaus-kaminski-97bb39230/" className="link"><img src={LinkedInIcon} alt="LinkedIn" /></a>
                        <a href="https://github.com/NikoKs1" className="link"><img src={GitHubIcon} alt="Github" /></a>
                    </div>
                </div>
            </div>
            <div className="member">
                <Image
                    alt="Philipp Kaminski"
                    className="avatar"
                    src={ require('../../../assets/team/img/Philipp_Kaminski.jpg') }
                    webp={ require('../../../assets/team/Philipp_Kaminski.webp') }
                />
                <div className="info">
                    <p className="name">Philipp Kaminski</p>
                    <p className="function">{t("Finance")}</p>
                    <div className="links">
                        <a href="https://www.linkedin.com/in/philipp-kaminski-1b3331231/" className="link"><img src={LinkedInIcon} alt="LinkedIn" /></a>
                        <a href="https://github.com/PhilKs" className="link"><img src={GitHubIcon} alt="Github" /></a>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

export default Team;
