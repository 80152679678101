import { useTranslation } from 'react-i18next';
import IconInstagram from '../assets/icons/instagram.svg';
import IconFacebook from '../assets/icons/facebook.svg';
import IconTwitter from '../assets/icons/twitter.svg';
import IconGithub from '../assets/icons/github.svg';
import IconLinkedin from '../assets/icons/linkedin.svg';
import IconSun from '../assets/icons/sun.svg';

function Footer() {

    const { t } = useTranslation();

    return <footer>
        <div className="group">
            <div className="icons">
                <a href="https://www.instagram.com/plutorix/" className="link"><img src={IconInstagram} alt="Instagram" /></a>
                <a href="https://twitter.com/plutorixinc" className="link"><img src={IconTwitter} alt="Twitter" /></a>
                <a href="https://www.linkedin.com/company/79025227" className="link"><img src={IconLinkedin} alt="Linkedin" /></a>
                <a href="https://github.com/plutorixinc" className="link"><img src={IconGithub} alt="Github" /></a>
                <a href="https://www.facebook.com/profile.php?id=100077518248623" className="link"><img src={IconFacebook} alt="Facebook" /></a>
                <button className="link" onClick={() => {
                    //document.getElementById('app').classList.toggle('dark');
                    //document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#268675');
                }}><img src={IconSun} alt="Facebook" /></button>
            </div> 
        </div>
        <div className="group">
            &copy; {new Date().getFullYear()} Plutorix - {t("All rights reserved")}
        </div>
    </footer>;
}

export default Footer;
